import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import './App.css'
import Experiment from "./Components/Experiment/Experiment"

const App = () => {
  const renderMain = () => <>
    <div className={'routed-pages-container'}>{renderRoutes()}</div>
  </>

  const renderRoutes = () => <>
    <Switch>
      {publicRoutes().map(r => r)}
      }
      <Route>
        <Experiment/>
      </Route>
    </Switch>
  </>

  const publicRoutes = () => [
    <Route key={'route-for-experiment'} path={"/experiment"}>
      <Experiment/>
    </Route>
  ]


  return <Router>
    <div id={'app'}>
      {renderMain()}
    </div>
  </Router>
}

export default App
