import React, {useState} from 'react'
import RadarChart from 'react-svg-radar-chart'
import 'react-svg-radar-chart/build/css/index.css'
import get from 'lodash/get'
import set from 'lodash/set'
import difference from 'lodash/difference'
import isArray from 'lodash/isArray'
import './Experiment.css'

const MAXIMUM_SCORE = 5

const Experiment = () => {
	const GRAPH_SIZE = 550
	const ASPECTS = ['Proficiency', 'Frequency', 'Enjoyment']
	const [data, setData] = useState({})
	const [people, setPeople] = useState([])
	const [things, setThings] = useState([])
	const [peopleInput, setPeopleInput] = useState('')
	const [thingsInput, setThingsInput] = useState('')
	const [aspectsToRender, setAspectsToRender] = useState([...ASPECTS])
	const [inExpampleMode, setInExampleMode] = useState(false)

	const updatePeople = input => {
		setInExampleMode(false)
		setPeopleInput(input)
		const newPeople = input.split(',')
		const newData = {...data}
		difference(people, newPeople).forEach(
			oldPerson => {
				delete newData[oldPerson]
			}
		)
		setData(newData)
		setPeople(newPeople)
	}

	const updateThings = input => {
		setInExampleMode(false)
		setThingsInput(input)
		const newThings = input.split(',').map(t => `${t}`)
		const newData = {...data}
		difference(things, newThings).forEach(
			oldThing => {
				Object.keys(newData).forEach(
					person => {
						delete newData[person][oldThing]
					}
				)
			}
		)
		setData(newData)
		setThings(newThings)
	}

	const getScoreFor = (person, thing, aspect) => get(data, `${person}.${thing}.${aspect}`, 0)

	const setScoreFor = (person, thing, aspect, score) => {
		if(isNaN(score) || score < 0 || score > 5) return
		const newData = {...data}
		const newDataToSet = set(newData, `${person}.${thing}.${aspect}`, score)
		setData(newDataToSet)
	}

	const updateAspectsToRender = aspect => {
		let newAspectsToRender = [...aspectsToRender]
		if(aspectsToRender.indexOf(aspect) >= 0) {
			newAspectsToRender = newAspectsToRender.filter(a => a !== aspect)
		}
		else {
			newAspectsToRender.push(aspect)
		}
		if(newAspectsToRender.length) {
			setAspectsToRender(newAspectsToRender)
		}
	}

	const loadExampleData = async () => {
		updatePeople('Alice,Bob,Carol')
		updateThings('Responding to incoming inquiries,Seeking feedback from clients,Running meetings about new product ideas,Writing code,Testing code,Checking ad performance,Creating content for ads,Paying invoices')
		setInExampleMode(true)
		const newData = {
			Alice: {
				'Responding to incoming inquiries': {
					[ASPECTS[0]]: 2,
					[ASPECTS[1]]: 0,
					[ASPECTS[2]]: 4,
				},
				'Seeking feedback from clients': {
					[ASPECTS[0]]: 3,
					[ASPECTS[1]]: 0,
					[ASPECTS[2]]: 4,
				},
				'Running meetings about new product ideas': {
					[ASPECTS[0]]: 1,
					[ASPECTS[1]]: 3,
					[ASPECTS[2]]: 1,
				},
				'Writing code': {
					[ASPECTS[0]]: 5,
					[ASPECTS[1]]: 4,
					[ASPECTS[2]]: 5,
				},
				'Testing code': {
					[ASPECTS[0]]: 4,
					[ASPECTS[1]]: 5,
					[ASPECTS[2]]: 4,
				},
				'Checking ad performance': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 1,
					[ASPECTS[2]]: 3,
				},
				'Creating content for ads': {
					[ASPECTS[0]]: 2,
					[ASPECTS[1]]: 2,
					[ASPECTS[2]]: 1,
				},
				'Paying invoices': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 0,
					[ASPECTS[2]]: 0,
				},
			},
			Bob: {
				'Responding to incoming inquiries': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 5,
					[ASPECTS[2]]: 0,
				},
				'Seeking feedback from clients': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 0,
					[ASPECTS[2]]: 0,
				},
				'Running meetings about new product ideas': {
					[ASPECTS[0]]: 4,
					[ASPECTS[1]]: 4,
					[ASPECTS[2]]: 5,
				},
				'Writing code': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 0,
					[ASPECTS[2]]: 0,
				},
				'Testing code': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 0,
					[ASPECTS[2]]: 0,
				},
				'Checking ad performance': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 0,
					[ASPECTS[2]]: 0,
				},
				'Creating content for ads': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 0,
					[ASPECTS[2]]: 0,
				},
				'Paying invoices': {
					[ASPECTS[0]]: 5,
					[ASPECTS[1]]: 4,
					[ASPECTS[2]]: 5,
				},
			},
			Carol: {

				'Responding to incoming inquiries': {
					[ASPECTS[0]]: 3,
					[ASPECTS[1]]: 5,
					[ASPECTS[2]]: 2,
				},
				'Seeking feedback from clients': {
					[ASPECTS[0]]: 1,
					[ASPECTS[1]]: 5,
					[ASPECTS[2]]: 1,
				},
				'Running meetings about new product ideas': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 3,
					[ASPECTS[2]]: 0,
				},
				'Writing code': {
					[ASPECTS[0]]: 5,
					[ASPECTS[1]]: 1,
					[ASPECTS[2]]: 0,
				},
				'Testing code': {
					[ASPECTS[0]]: 1,
					[ASPECTS[1]]: 1,
					[ASPECTS[2]]: 0,
				},
				'Checking ad performance': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 0,
					[ASPECTS[2]]: 0,
				},
				'Creating content for ads': {
					[ASPECTS[0]]: 0,
					[ASPECTS[1]]: 0,
					[ASPECTS[2]]: 0,
				},
				'Paying invoices': {
					[ASPECTS[0]]: 5,
					[ASPECTS[1]]: 4,
					[ASPECTS[2]]: 5,
				},
			}
		}

		setData(newData)
	}

	const renderScoringTidbit = () => renderTidbit({
		title: 'Ask each person to fill out the section that corresponds to them',
		copy: <div>
			<h4>
				Give a score out of 5 for each aspect of each "thing".
			</h4>
			<p>
				Answer as follows. Proficiency: How good do you think you would be at this thing?
			</p>
			<p>
				Frequency: How often do you do this thing?
			</p>
			<p>
				Enjoyment: How much would you enjoy doing this thing?'
			</p>
		</div>
	})

	const emoji = e => <span className={'infomoji'} role={'img'} aria-label={`emoji`}>{e}</span>

	const renderThingsTidbit = () => renderTidbit({
		title: 'Interpreting a "thing" breakdown graph',
		copy: <div>
			<p>
				Consider the first graph. It is related to the "thing": Responding to incoming inquiries.
			</p>
			<p>
				If it helps, use the buttons at the bottom of the screen to toggle between aspects (proficiency, enjoyment, frequency)
			</p>
			<p>
				Carol and Bob most frequently respond to incoming inquries.
			</p>
			<p>
				{emoji('‼')} Bob does not enjoy this. Neither does he feel himself proficient.
			</p>
			<p>
				{emoji('❗')} Carol enjoys this slightly, and feels herself reasonably proficient.
			</p>
			<p>
				{emoji('💡')} Alice feels that she would be good at this, and enjoy it alot.
			</p>
			<p>
				{emoji('💡')} Could Alice be best suited to this task?
			</p>
			<p>
				{emoji('💡')} If considering bringing on another team memeber, could their interest and ability in this task be of interest?
			</p>
			<p>
				{emoji('❓')} Look at the rest of the things breakdowns below. Are there any things that nobody is addressing frequently?
			</p>
			<p>
				{emoji('❓')} Look at the rest of the things breakdowns below. Are there any things that could be better re-assigned?
			</p>
			<p>
				{emoji('💡')} A new company member could find this breakdown useful in understanding who does what kinds of things in the company.
			</p>
		</div>
	})

	const renderPersonGraphTidbit = () => renderTidbit({
		title: `Interpreting Alice's graph`,
		copy: <div>
			<p>
				{emoji('✅')} Alice frequently writes and tests code, and runs product meetings. She feels that she is proficient at these things.
			</p>
			<p>
				{emoji('✅')} Alice enjoys the coding side of work.
			</p>
			<p>
				{emoji('❗')} Alice doesn't seem to enjoy running product meetings, she also doesn't think she's very good at it. But she does this frequently.
			</p>
			<p>
				{emoji('💡')} Alice thinks she would really enjoy client interaction, and that she would be good at it. However, this is not something Alice ever does.
			</p>
			<p>
				Alice has some experience creating ad content, but does not work in that area.
			</p>
			<p>
				Alice does not know much about checking ad performance, but seems like she might like to expand her skills there.
			</p>
		</div>
	})

	const renderScoresInput = () => <div className={'scores-input'}>
		{
			people.map(
				person => <div className={'person'} key={`scores-for-${person}`}>
					{
						inExpampleMode && person==='Alice' && renderScoringTidbit()
					}
					<h2 className={'person-name'}>{person}</h2>
					{
						things.map(
							thing => <div className={'thing'}  key={`scores-for-${person}-${thing}`}>
								<div className={'thing-title'}>{thing}</div>
								<div className={'thing-aspects'}>
									{
										ASPECTS.map(
											aspect => <div className={`score-element score-element__${aspect}`} key={`scores-for-${person}-${thing}-${aspect}`}>
												<div className={'score-element-title'}>{aspect}</div>
												<input
													type={'text'}
													maxLength={1}
													className={'score-element-score'}
													value={getScoreFor(person, thing, aspect)}
													onChange={e => setScoreFor(person, thing, aspect, e.target.value)}
												/>
											</div>
										)
									}
								</div>
							</div>
						)
					}

					{
						inExpampleMode && person==='Alice' && renderPersonGraphTidbit()
					}

					<div className={'graph-container'}>
						{
							(
								() => {
									const graphData = getDataAndCaptionsTypeThingsForAPerson({person, useAspects: aspectsToRender})
									return validateGraphData({graphData}) && <RadarChart
										captions={graphData.graphCaptions}
										data={graphData.graphData}
										size={GRAPH_SIZE}
										options={{
											dots: true,
											scales: 5,
											captionMargin: 10,
											zoomDistance: 1,
											captionProps: () => ({
												className: 'caption experiment-caption',
												textAnchor: 'middle',
												fontSize: 10,
												fontFamily: 'sans-serif'
											}),
										}}
									/>
								}
							)()
						}
					</div>
				</div>
			)
		}
	</div>

	const renderThingsAndBreakdowns = () => <div className={'things-and-breakdowns'}>
		{
			things && things.length > 0 && <h1>Things Breakdown</h1>
		}
		{
			things.map(
				thing => <div className={'breakdown'}  key={`breakdown-for-${thing}`}>
					{
						inExpampleMode && thing==='Responding to incoming inquiries' && renderThingsTidbit()
					}
					<h2 className={'breakdown-title'}>{thing}</h2>
					<div className={'graph-container'}>
						{
							(
								() => {
									const graphData = getDataAndCaptionsTypePeopleForAThing({thing, useAspects: aspectsToRender})
									return validateGraphData({graphData}) && <RadarChart
										captions={graphData.graphCaptions}
										data={graphData.graphData}
										size={GRAPH_SIZE}
										options={{
											dots: true,
											scales: 5,
											captionMargin: 10,
											zoomDistance: 1,
											captionProps: () => ({
												className: 'caption experiment-caption',
												textAnchor: 'middle',
												fontSize: 10,
												fontFamily: 'sans-serif'
											}),
										}}
									/>
								}
							)()
						}
					</div>
				</div>
			)
		}
	</div>

	const renderSelectAspectsToRender = () => <div className={'aspects-to-render'}>
		<div className={'aspects-to-render-instruction'}>
			Select which to show:
		</div>
		{
			ASPECTS.map(
				aspect => <div
					onClick={() => updateAspectsToRender(aspect)}
					className={`select-aspect-to-render select-aspect-to-render__${aspect} ${(aspectsToRender.indexOf(aspect) < 0) ? `select-aspect-to-render__aspect-disabled` : ''}`}  key={`select-aspect-to-render-${aspect}`}
				>
					{aspect}
				</div>
			)
		}
	</div>

	const getAspectColor = aspect => {
		return {
			[ASPECTS[0]]: '#2aecbb',
			[ASPECTS[1]]: '#a51fec',
			[ASPECTS[2]]: '#ff7709',
		}[aspect]
	}

	const getAspectFill = aspect => {
		return {
			[ASPECTS[0]]: '#2aecbb',
			[ASPECTS[1]]: '#a51fec',
			[ASPECTS[2]]: '#ff7709',
		}[aspect]
	}

	const getAspectFillOpacity = aspect => {
		return {
			[ASPECTS[0]]: 0.2,
			[ASPECTS[1]]: 0.2,
			[ASPECTS[2]]: 0.2,
		}[aspect]
	}

	const getAspectStrokeWidth = aspect => {
		return {
			[ASPECTS[0]]: 4,
			[ASPECTS[1]]: 2,
			[ASPECTS[2]]: 1,
		}[aspect]
	}

	const renderTidbit = ({title, copy, buttonText, buttonClick} = {}) => <div className={'tidbit'}>
		<div className={'tidbit-info'}>
			<div className={'tidbit-title'}>{title}</div>
			<div className={'tidbit-copy'}>{copy}</div>
		</div>
		{
			buttonText && <button className={'load-example-button'} onClick={buttonClick}>{buttonText}</button>
		}
	</div>

	const validateGraphData = ({graphData}) => {
		let result = true
		const {graphData: dataSet} = graphData
		/* eslint-disable */
		validation: {
			if(!graphData.graphCaptions) {
				result = false
				break validation
			}
			if(!isArray(dataSet)) {
				result = false
				break validation
			}
			if(!dataSet.every(d => d.data)) {
				result = false
				break validation
			}
			if(Object.keys(graphData?.graphCaptions)?.length === 0) {
				result = false
				break validation
			}
			if(Object.keys(graphData?.graphCaptions)?.length !== Object.keys(dataSet[0]?.data)?.length) {
				result = false
				break validation
			}
		}
		return result
	}

	const getDataAndCaptionsTypeThingsForAPerson = ({person, useAspects}) => {
		const graphCaptions = {}
		const graphData = []

		Object.keys(get(data, `${person}`, {})).forEach(
			thing => {
				set(graphCaptions, `${thing}`, `${thing}`)
			}
		)

		useAspects.forEach(
			aspectToUse => {
				const thingDataSet = {
					meta: {
						color: getAspectColor(aspectToUse),
						strokeWidth: getAspectStrokeWidth(aspectToUse),
						fill: getAspectFill(aspectToUse),
						fillOpacity: getAspectFillOpacity(aspectToUse),
					},
					data: {}
				}
				Object.keys(get(data, `${person}`, {})).forEach(
					thing => {
						set(thingDataSet, `data.${thing}`, Number(get(data, `${person}.${thing}.${aspectToUse}`, 0))/MAXIMUM_SCORE)
					}
				)
				graphData.push(thingDataSet)
			}
		)

		return {
			graphCaptions,
			graphData
		}
	}

	const getDataAndCaptionsTypePeopleForAThing = ({thing, useAspects}) => {
		const graphCaptions = {}
		const graphData = []
		Object.keys(data).forEach(
			person => {
				set(graphCaptions, `${person}`, `${person}`)
			}
		)

		useAspects.forEach(
			aspectToUse => {
				const personDataSet = {
					meta: {
						color: getAspectColor(aspectToUse),
						strokeWidth: getAspectStrokeWidth(aspectToUse),
						fill: getAspectFill(aspectToUse),
						fillOpacity: getAspectFillOpacity(aspectToUse),
					},
					data: {}
				}
				Object.keys(data).forEach(
					person => {
						set(personDataSet, `data.${person}`, Number(get(data, `${person}.${thing}.${aspectToUse}`, 0))/MAXIMUM_SCORE)
					}
				)
				graphData.push(personDataSet)
			}
		)

		return {
			graphCaptions,
			graphData
		}
	}

	return <div className={'experiment-container'}>
		<h1>Who Does What?</h1>

		{
			renderTidbit({
				title: 'How this tool works',
				copy: 'Load some example data for a demo on how this tool works.',
				buttonText: 'load example',
				buttonClick: loadExampleData,
			})
		}

		<div className={'main-inputs'}>
			<div  className={'main-input'}>
				<h3 className={'main-input-instructions'}>Enter a list of people (or teams, divisions)</h3>
				<textarea
					placeholder={'E.g. Alice, Bob, Carol'}
					value={peopleInput}
					onChange={e => updatePeople(e.target.value)}
				/>
			</div>
			<div className={'main-input'}>
				<h3 className={'main-input-instructions'}>Enter a list of things (tasks / duties / responsibilities)</h3>
				<textarea
					placeholder={'E.g. Contacting leads, Graphic design, Front-end programming, Taking meeting notes'}
					value={thingsInput}
					onChange={e => updateThings(e.target.value)}
				/>
			</div>
		</div>
		{renderScoresInput()}
		{renderThingsAndBreakdowns()}
		{renderSelectAspectsToRender()}
	</div>
}

export default Experiment
